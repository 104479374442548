<template>
  <!--
  *@des:高层次人才经济贡献奖
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        v-model="form.applyName"
        name="applyName"
        label="姓名"
        placeholder="请输入姓名"
        required
        readonly
        disabled
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
        readonly
        disabled
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field-select
        v-model="form.homeType"
        :options="homeTypeOption"
        :fieldProps="{
          label: '房间类型',
          required: true,
          placeholder: '请选择房间类型',
        }"
      ></van-field-select>
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
export default {
  name: "high-talent-devote",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        idCardNum: "", //证件号码
        phone: "",
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            mode: "pattern",
            message: "请检查手机号格式",
            pattern:
              "^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$",
          },
        ],
        homeType: {
          required: true,
          message: "请选择房间类型",
        },
      },
      homeTypeOption: [
        {
          value: "标间",
          label: "标间",
        },
        {
          value: "四人间",
          label: "四人间",
        },
      ],
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  created() {},
  methods: {
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      this.initFormValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve({
              ...this.form,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
</style>